import { Helmet } from 'react-helmet';

const SiteTitle: React.FC<{ children: string | string[] }> = ({ children }) => {    
    return (
        <Helmet>
            <title>{children}</title>
        </Helmet>
    )
}

export default SiteTitle