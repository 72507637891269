import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import getAnalytics from "../firebase/analytics";

const usePageAnalytics = () => {
  const location = useLocation();

  getAnalytics()

  useEffect(() => {
    //@ts-ignore
    const gtag = window.gtag
    
    // track pageview with gtag / react-ga / react-ga4, for example:
    gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageAnalytics;