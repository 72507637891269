// import './index.css'

import { NoSsr } from "@mui/material"
import FullHeightBodyInternal from "./Internal"

const FullHeightBody: React.FC = () => {
    return (
        <NoSsr>
            <FullHeightBodyInternal />
        </NoSsr>
    )
}

export default FullHeightBody