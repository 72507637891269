import { PropsWithChildren } from "react";
import { materialTheme, joyTheme } from '../theme';
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {
    THEME_ID as JOY_THEME_ID
} from "@mui/joy/styles"
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
const COLOR_SCHEME: "system" | "dark" | "light" = "dark"

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: `body { background-color: #141311 }` }} />

            <MaterialCssVarsProvider
                theme={{ [MATERIAL_THEME_ID]: materialTheme }}
                defaultMode={COLOR_SCHEME}
                modeStorageKey="mui-mode-scheme-frontend"
            >
                <JoyCssVarsProvider
                    defaultMode={COLOR_SCHEME}
                    theme={{ [JOY_THEME_ID]: joyTheme }}
                    modeStorageKey="joy-mode-scheme-frontend"
                >
                    {children}
                </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
        </>
    )
}

export default ThemeProvider