import FullHeightBody from "../components/css/FullHeightBody"
import usePageAnalytics from "../util/hooks/usePageAnalytics"
import CSSReset from "../components/css/CSSReset"
import SiteTitle from "../components/SiteTitle"
import studioConfig from "../studioConfig"
import { Box } from "@mui/material"
import RouterLink from "../components/RouterLink"
import ThemeProvider from "../components/ThemeProvider"
import { Container, Typography } from "@mui/joy"

// TODO hover color for 'button's
const LandingPage: React.FC = () => {
    usePageAnalytics()

    return (
        <ThemeProvider>
            <CSSReset />

            <SiteTitle>{studioConfig.name}</SiteTitle>

            <FullHeightBody />

            {/* TODO lower resolution on background (currently 5K!), add blur & smoke */}
            <Box
                sx={{
                    height: "100vh",
                    backgroundColor: studioConfig.color.background, // Fallback color
                    backgroundImage: "url(/asset/landing/smoke2.png)",

                    backgroundRepeat: "no-repeat",

                    // backgroundBlendMode: "lighten",
                    // backgroundPosition: "bottom, center center",
                    // backgroundSize: "contain, cover",

                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                }}
            >
                <Container sx={{
                    height: "inherit",

                    display: "flex",
                    flexDirection: "column",

                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <Box
                        style={{
                            flexGrow: 2,

                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",

                            // alignItems: "center"
                        }}
                    >
                        <img
                            src="/asset/landing/text.png"
                            alt="Sundog Tattoos"

                            style={{ /*flexGrow: 1,*/ height: "5vh" }}
                        />
                    </Box>

                    <Box
                        style={{
                            flexGrow: 3,

                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            // alignItems: "center"
                        }}
                    >
                        <Typography
                            component={RouterLink}
                            to="/book/sundog"

                            sx={{
                                fontFamily: "Chomsky",
                                fontSize: "3em",

                                textAlign: "center",

                                textDecorationThickness: "2px",

                                color: "#7e7260",

                                zIndex: 10,

                                "&:hover": {
                                    color: "#594E42"
                                },
                            }}
                        >
                            booking
                        </Typography>

                        {/* TODO can I get away with halving this image's pixel count? */}
                        <img
                            src="/asset/landing/gargoyle.png"
                            alt="Sundog Tattoos"
                            style={{
                                height: "30vh",
                                // width: "100%",
                                transform: "translate(-35%, -45%)",
                            }}
                        />
                    </Box>

                    <Box sx={{
                        flexGrow: 1
                    }} />
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default LandingPage