import { combineReducers, configureStore } from '@reduxjs/toolkit'

import markdownSlice from './slices/markdown'
import firestoreSlice from './slices/firestore'
import firebaseFunctionsSlice from './slices/firebaseFunctions'
import clientSlice from './slices/client'

const rootReducer = combineReducers({
  firestore: firestoreSlice.reducer,
  markdown: markdownSlice.reducer,
  firebaseFunctionsSlice: firebaseFunctionsSlice.reducer,
  client: clientSlice.reducer,
})

const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
