import { Box } from "@mui/material"
import { PropsWithChildren, ComponentProps } from "react"

/**
 * Center child components
 */
const Center: React.FC<PropsWithChildren<{
    centerVertical?: "top" | "bottom" | "center" | false,
    centerHorizontal?: "left" | "right" | "center" | false,
}> & ComponentProps<typeof Box>> = ({
    /** mode to center vertically. top, false and bottom don't center. false is shorthand for top */
    centerVertical,
    /** mode to center horizontally. left, false and right don't center. false is shorthand for right */
    centerHorizontal,
    children,
    ...props
}) => {
        if (centerHorizontal === false) centerHorizontal = "left"

        if (centerVertical === false) centerVertical = "top"

        centerHorizontal = centerHorizontal ?? "center"
        centerVertical = centerVertical ?? "center"

        const justifyContent = {
            "left": "flex-start",
            "right": "flex-end",
            "center": "center",
        }[centerHorizontal]

        const alignItems = {
            "top": "flex-start",
            "bottom": "flex-end",
            "center": "center",
        }[centerVertical]

        return (
            <Box
                display="flex"
                justifyContent={justifyContent}
                alignItems={alignItems}
                {...props}
            >
                {children}
            </Box>
        )
    }

export default Center