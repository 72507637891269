
import { NoSsr } from "@mui/material";
import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

// Links use useLayoutEffect. Thanks react-router-dom :(

const RouterLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    return (
        <NoSsr>
            <Link ref={ref} {...props} />
        </NoSsr>
    )
})

export default RouterLink