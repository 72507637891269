
enum actionTypes {
    REQUEST_MARKDOWN = "markdown/request",
    FIRESTORE_QUERY = "firestore/query",
    FIRESTORE_QUERY_MULTIPLE = "firestore/query_multiple",
    INVALIDATE_COLLECTION = "firestore/invalidate/collection",
    INVALIDATE_QUERIES = "firestore/invalidate/queries",
    FIRESTORE_COLLECTION = "firestore/collection",
    GET_AVAILABILITY = "firebase/function/get_availability",
}

export default actionTypes