import { NoSsr } from "@mui/material"
import CSSResetInternal from "./Internal"

const CSSReset: React.FC = () => {
    return (
        <NoSsr>
            <CSSResetInternal />
        </NoSsr>
    )
}

export default CSSReset