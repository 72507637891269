import React, { lazy, PropsWithChildren, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux'
import store from './util/redux/store'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import DefaultLayout from './layouts/DefaultLayout';
import ErrorComponent from './pages/Error';

import FlashPage from './pages/flash';
//import ArchivePage from './pages/archive'
//import AboutPage from './pages/About';
import LandingPage from './pages/Landing';

//const ArchiveDetailPage = lazy(() => import('./pages/archive/Detail'))
//const FlashDetailPage = lazy(() => import('./pages/flash/Detail'));
// const FlashDetailRedirectPage = lazy(() => import('./pages/flash/RequestRedirect'));
// const ArtistPage = lazy(() => import('./pages/artist/Artist'));
// const ArtistLayout = lazy(() => import('./layouts/ArtistLayout'));
// const AllArtists = lazy(() => import('./pages/artist/All'));
// const RequestFlashPage = lazy(() => import('./pages/flash/request/index'));
const FilePage = lazy(() => import('./pages/File'))

const BookIndexPage = lazy(() => import('./pages/book/index'))
const BookRequestPage = lazy(() => import('./pages/book/Request'))

const AuthLogin = lazy(() => import('./pages/auth/Login'))
const AuthIndex = lazy(() => import('./pages/auth/AuthIndex'));
const AuthNotAllowed = lazy(() => import('./pages/auth/NotAllowed'));

//const ScheduleRequestPage = lazy(() => import('./pages/manage/request/Schedule'))
const ManageRequestPage = lazy(() => import('./pages/manage/request'));
const ManageLayout = lazy(() => import('./layouts/ManageLayout'))
const ManageEditProfilePage = lazy(() => import('./pages/manage/EditProfile'))
const ManageIndexPage = lazy(() => import('./pages/manage/ManageIndex'))
const ManageCreateFlashPage = lazy(() => import('./pages/manage/flash/CreateFlash'))
const ManageEditFlashPage = lazy(() => import('./pages/manage/flash/EditFlash'))
//const EditCalendarPage = lazy(() => import('./pages/manage/EditCalendar'));
const ManageCalendarPage = lazy(() => import('./pages/manage/Calendar'))
const ManageEditArchivePage = lazy(() => import('./pages/manage/archive/Edit'))
const ManageCreateArchiveItemPage = lazy(() => import('./pages/manage/archive/Create'));
const ManageFilesPage = lazy(() => import('./pages/manage/files/FilesPage'))

// self executing anonymous function is bad pattern but 
// this is a really easy / clean-looking way to reduce bundled size 
// assuming your treeshaker works at all
const developerRoutes = (() => {
  if (process.env.NODE_ENV !== "development") return {}

  const DeveloperIndexPage = lazy(() => import('./pages/developer'));
  const DeveloperLayout = lazy(() => import('./layouts/DeveloperLayout'));
  const DeveloperFlashCardDemoPage = lazy(() => import('./pages/developer/components/FlashCardDemo'));
  const DeveloperMobileFriendlyTimePickerPage = lazy(() => import('./pages/developer/components/MobileFriendlyTimePicker'));
  const DeveloperTestQueryTypesPage = lazy(() => import('./pages/developer/TestQueryTypes'));
  const DeveloperGetAvailabilityV2Page = lazy(() => import('./pages/developer/GetAvailabilityV2'));
  const DeveloperFirebaseSecurityPage = lazy(() => import('./pages/developer/FirebaseSecurity'));
  const DeveloperConfettiButtonPage = lazy(() => import('./pages/developer/components/ConfettiButton'))
  const DeveloperMarkdownInputPage = lazy(() => import('./pages/developer/components/MarkdownInput'))
  const DeveloperPublicizeImagesPage = lazy(() => import('./pages/developer/PublicizeImage'))
  const DeveloperPinterestStylePage = lazy(() => import('./pages/developer/components/PinterestStyle'))
  const DeveloperAvailableTimeDatePickerPage = lazy(() => import('./pages/developer/components/AvailableDateTimePicker'))
  const DeveloperRefreshCalendarTokensPage = lazy(() => import('./pages/developer/RefreshCalendarTokens'))
  const DeveloperFontColorsPage = lazy(() => import('./pages/developer/components/FontColors'));
  const DeveloperInvalidateCollectionPage = lazy(() => import('./pages/developer/InvalidateCollection'))
  const DeveloperManuallyDeleteUPloadsPage = lazy(() => import('./pages/developer/ManuallyDeleteUploads'))

  return {
    path: "/dev",

    element: <DeveloperLayout />,

    children: [
      { path: "/dev", element: <DeveloperIndexPage /> },
      { path: "/dev/firebase-security", element: <DeveloperFirebaseSecurityPage /> },
      { path: "/dev/c/confetti", element: <DeveloperConfettiButtonPage /> },
      { path: "/dev/c/flash-card", element: <DeveloperFlashCardDemoPage /> },
      { path: "/dev/c/markdown-input", element: <DeveloperMarkdownInputPage /> },
      { path: "/dev/c/time-picker", element: <DeveloperMobileFriendlyTimePickerPage /> },
      { path: "/dev/c/pinterest-style", element: <DeveloperPinterestStylePage /> },
      { path: "/dev/c/available-datetime-picker", element: <DeveloperAvailableTimeDatePickerPage /> },
      { path: "/dev/c/theme-colors", element: <DeveloperFontColorsPage /> },
      { path: "/dev/test-cached-query", element: <DeveloperTestQueryTypesPage /> },
      { path: "/dev/get-availability", element: <DeveloperGetAvailabilityV2Page /> },
      { path: "/dev/publicize-images", element: <DeveloperPublicizeImagesPage /> },
      { path: "/dev/refresh-calendar-tokens", element: <DeveloperRefreshCalendarTokensPage /> },
      { path: "/dev/invalidate-collection", element: <DeveloperInvalidateCollectionPage /> },
      { path: "/dev/manually-delete-uploads", element: <DeveloperManuallyDeleteUPloadsPage /> }
    ]
  }
})()

export const routes: RouteObject[] = [
  // Legacy routes
  // { path: "/custom", element: <Navigate to="/book" /> },
  // { path: "/custom/:artist", element: <Navigate to="/book" /> },
  // { path: "/flash/:artist/:slug/request", element: <FlashDetailRedirectPage /> },

  {
    path: "/", element: <LandingPage />, errorElement: <ErrorComponent />,
  },

  { path: "/files/:artist/:filename/raw", element: <FilePage raw /> },

  {
    element: <DefaultLayout />,

    errorElement: <ErrorComponent />,


    children: [
      // { path: "/flash", element: <FlashPage /> },
      // { path: "/flash/:artist", element: <FlashPage /> },
      //{ path: "/flash/:artist/:slug", element: <FlashDetailPage /> },
      // { path: "/flash/:artist/:slug", element: <RequestFlashPage /> },
      // { path: "/healed", element: <ArchivePage /> },
      // { path: "/healed/:artist", element: <ArchivePage /> },
      //{ path: "/healed/:artist/:slug", element: <ArchiveDetailPage /> },
      // { path: "/about", element: <AboutPage /> },
      { path: "/files/:artist/:filename", element: <FilePage /> },

      /*{
        path: "/artist",
        element: <ArtistLayout />,

        children: [
          { path: "/artist", element: <AllArtists /> },
          { path: "/artist/:slug", element: <ArtistPage /> }
        ]
      },*/

      { path: "/book", element: <BookIndexPage /> },
      { path: "/book/:artist", element: <BookRequestPage /> },

      {
        path: "/auth",
        children: [
          { path: "/auth", element: <AuthIndex /> },
          { path: "/auth/login", element: <AuthLogin /> },
          { path: "/auth/denied", element: <AuthNotAllowed /> },
        ]
      },
    ],
  },

  // { path: "/landing", element: <LandingPage /> },

  {
    path: "/manage",
    element: <ManageLayout />,

    children: [
      { path: "/manage", element: <ManageIndexPage /> },
      { path: "/manage/info", element: <ManageEditProfilePage /> },
      { path: "/manage/flash/create", element: <ManageCreateFlashPage /> },
      { path: "/manage/flash/edit/:slug", element: <ManageEditFlashPage /> },
      { path: "/manage/request/:id", element: <ManageRequestPage /> },
      //{ path: "/manage/request/:id/schedule", element: <ScheduleRequestPage /> },
      { path: "/manage/calendar", element: <ManageCalendarPage /> },

      { path: "/manage/files", element: <ManageFilesPage /> },

      { path: "/manage/archive/edit/:slug", element: <ManageEditArchivePage /> },
      { path: "/manage/archive/create", element: <ManageCreateArchiveItemPage /> }
      //{ path: "/manage/calendar", element: <EditCalendarPage /> }
    ]
  },

  developerRoutes
]

const App: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <React.StrictMode>
      <Suspense>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {children}
          </LocalizationProvider>
        </ReduxProvider>
      </Suspense>
    </React.StrictMode>
  )
}

export default App