import { PropsWithChildren } from "react";
import CSSReset from "../components/css/CSSReset";
import FullHeightBody from "../components/css/FullHeightBody";
import SiteTitle from "../components/SiteTitle";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Footer from "../components/Footer";
import ThemeProvider from "../components/ThemeProvider";
import studioConfig from '../studioConfig'
import usePageAnalytics from "../util/hooks/usePageAnalytics";
// import useServerEffect from "../util/hooks/server/useServerEffect";

const DefaultLayout: React.FC<PropsWithChildren> = ({ children }) => {
    /*
    useServerEffect(() => {
        console.log("I'm a server!")
    }, [])
    */

    usePageAnalytics();

    return (
        <>
            <ThemeProvider>
                <CSSReset />

                <FullHeightBody />

                <SiteTitle>{studioConfig.name}</SiteTitle>

                <Box sx={{
                    backgroundColor: studioConfig.color.background, // Fallback color
                }}>
                    <Box
                        sx={{
                            minHeight: "100vh",
                            /*
                            backgroundImage: "url(/asset/bg-tile.png)",
                            backgroundSize: {
                                xs: `60vw auto`,
                                lg: "40vw auto",
                                xl: "25vw auto",
                            },
                            backgroundPosition: `50vw 50vh`,
                            */
                            paddingY: "15px"
                        }}
                    >
                        <Outlet />

                        {/** Children so ErrorComponent etc can use the same layout */}
                        {children}
                    </Box>

                    <Footer />
                </Box>
            </ThemeProvider>
        </>
    )
}

export default DefaultLayout