import reportWebVitals from './reportWebVitals';

import App, { routes } from './App'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';

const browserRouter = createBrowserRouter(routes)

const RoutedApp = () => {
  return (
    <App>
      <RouterProvider router={browserRouter} />
    </App>
  )
}

const main = () => {
  const rootElement = document.getElementById('root') as HTMLElement

  if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, <RoutedApp />)

    //root.hydrate(<RoutedApp />, rootElement);
  } else {
    const root = createRoot(rootElement)

    root.render(<RoutedApp />);
  }
}

main()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
