import { Typography } from "@mui/joy"
import { Box, Container } from "@mui/material"
import RouterLink from "./RouterLink"

const Footer: React.FC = () => {
    return (
        <Box sx={{
            // backgroundColor: "secondary.main",
            color: "secondary.contrastText",
            py: 5
        }}>
            <Container>
                <Box display="flex" justifyContent="center" gap="25px">
                    <Box>
                        <Typography sx={{
                            color: "white"
                        }}>
                            Made with &lt;3 by&nbsp;

                            <RouterLink to="https://www.instagram.com/bugshit5" style={{ color: "white" }}>zingle</RouterLink>
                        </Typography>
                    </Box>

                    <Box>
                        {process.env.NODE_ENV === "development" &&
                            <Typography sx={{
                                color: "white"
                            }}>
                                <RouterLink to="/dev" style={{ color: "white" }}>secreter dev button!</RouterLink>
                            </Typography>
                        }

                        <Typography
                            sx={{
                                color: "white",
                                textDecoration: "none"
                            }}
                            component={RouterLink}
                            to="/auth"
                        >
                            ...
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Footer