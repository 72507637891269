
import { Firestore, getFirestore as getFirebaseFirestore } from "@firebase/firestore"
import getApp from "."

let firestore: Firestore

const getFirestore = () => {
    const app = getApp()

    if (!firestore)
        firestore = getFirebaseFirestore(app)

        return firestore
}

export default getFirestore