import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import actionTypes from "../actionTypes";

export const fetchMarkdown = createAsyncThunk(
    actionTypes.REQUEST_MARKDOWN,
    (url: string) => {
        return fetch(url)
            .then(response => response.text())
            .then(text => {
                return { text, url }
            })
    }
)

const initialState: Record<string, string> = {

}

const markdownSlice = createSlice({
    name: "markdown",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMarkdown.fulfilled, (state, action) => {
            state[action.payload.url] = action.payload.text
        })
    }
})

export default markdownSlice