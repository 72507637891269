import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import actionTypes from "../actionTypes";
import getFirestore from "../../firebase/firestore";
import {
    collection as getCollection,
    getDoc,
    getDocs,
    doc as queryDoc,
    query as firestoreQuery,
    where,
    limit,
    DocumentData,
    OrderByDirection,
    DocumentSnapshot
} from '@firebase/firestore'
import ArtistInfo from "tattoo_types/src/ArtistInfo";
import FlashInfo from "tattoo_types/src/FlashInfo";
import notEmpty from "tattoo_types/dist/notEmpty";
import isNestedArray from "../../isNestedArray";

export type FirestoreWhere = Parameters<typeof where>

const db = getFirestore()

export type FirestoreQuery = {
    collection: string
    doc?: string

    where?: FirestoreWhere | FirestoreWhere[]

    limit?: number

    orderBy?: { field: string, direction?: OrderByDirection }

    // TODO FIXME orderBy - and then order /flash by date to fix sorting issue
}

const callFirestoreQuery = (query: FirestoreQuery) => {
    if (query.doc) {
        return getDoc(queryDoc(db, query.collection, query.doc))
            .then(snapshot => [snapshot])
    } else {
        // TODO feels a bit dirty but this works for now
        const queries: FirestoreWhere[] = query.where ?
            (isNestedArray(query.where) ? query.where : [query.where]) :
            []

        const wheres = queries.map(query => where(query[0], query[1], query[2]))

        const limits = query.limit ? [limit(query.limit)] : []

        //const orderBy = query.orderBy ? [firestoreOrderBy(query.orderBy.field, query.orderBy.direction)] : []

        return getDocs(firestoreQuery(
            getCollection(db, query.collection),
            //...orderBy,

            ...wheres,
            ...limits,
        ))
            .then((snapshot) => snapshot.docs)
    }
}

const onlyExistingDocs = <A, B extends DocumentData>(docs: DocumentSnapshot<A, B>[]) =>
    docs.map(doc => doc.exists() ? doc : undefined).filter(notEmpty)

// Solution to fix google's shitty ass database not letting orderBy work
const customOrderBy = (data: {
    query: FirestoreQuery;
    documents: {
        data: DocumentData;
        id: string;
    }[]
}) => {
    const query = data.query

    if (query.orderBy) {
        const docs = data.documents

        const { field, direction } = query.orderBy

        const directionMultiplier = direction === "desc" ? -1 : 1

        // TODO could get slow, but for now is ok.
        return {
            ...data,

            documents: docs.sort((a, b) => {
                const compare = a.data[field] - b.data[field]

                return directionMultiplier * compare
            })
        }
    } else {
        return data
    }

}

// TODO de-nest this mf. wtf is going on in here.
export const fetchFromFirestore = createAsyncThunk(
    actionTypes.FIRESTORE_QUERY_MULTIPLE,
    (query: FirestoreQuery) => {
        return callFirestoreQuery(query)
            .then(onlyExistingDocs)
            .then((docs) => {
                return {
                    query,
                    documents: docs.map(document => {
                        return {
                            data: document.data(),
                            id: document.id
                        }
                    })
                }
            })
            .then(customOrderBy)
    }
)

type FirestoreData = {
    collections: {
        artist: Record<string, ArtistInfo>
        flash: Record<string, FlashInfo>

        [key: string]: Record<string, DocumentData>
    }

    queries: Record<string, {
        query: FirestoreQuery,
        documents: string[]
    }[]>
}

const initialState: FirestoreData = {
    collections: {
        artist: {},
        flash: {},
    },
    queries: {}
}

const firestoreSlice = createSlice({
    name: "firestore",
    initialState,
    reducers: {
        invalidateCollection: (state, data: PayloadAction<keyof FirestoreData['collections']>) => {
            const collection = data.payload

            const { collections, queries, ...otherState } = state

            const newCollections = { ...collections }
            delete newCollections[collection]

            const newQueries = { ...queries }
            delete newQueries[collection]

            return {
                collections: newCollections,
                queries: newQueries,

                ...otherState
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFromFirestore.fulfilled, (state, action) => {
            const { query, documents } = action.payload
            
            const collection = query.collection

            const ids = documents.map(document => {
                const { id, data } = document

                if (!state.collections[collection]) state.collections[collection] = {}

                state.collections[collection][id] = data

                return id
            })

            if (!state.queries[collection]) state.queries[collection] = []

            state.queries[collection].push({
                query,
                documents: ids
            })
        })
    }
})

export const { invalidateCollection } = firestoreSlice.actions

export default firestoreSlice