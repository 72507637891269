import { Functions, HttpsCallableResult, getFunctions as getFirebaseFunctions, httpsCallable } from "firebase/functions"
import getApp from "."
import { SetPieceImagesParams, SetPieceImagesReturn } from "tattoo_types/src/functions/SetPieceImages";

import { SetArtistProfileParams, SetArtistProfileReturn } from "tattoo_types/src/functions/SetArtistProfile"
import { GenerateFlashSlugParams, GenerateFlashSlugReturn } from "tattoo_types/src/functions/GenerateFlashSlug"
import { SetFlashParams, SetFlashReturn } from "tattoo_types/src/functions/SetFlash"
import { DeleteFlashParams, DeleteFlashReturn } from "tattoo_types/src/functions/DeleteFlash"
import { GetAvailabilityParams, GetAvailabilityReturn } from "tattoo_types/src/functions/GetAvailability_v2"
import { SetCalendarSecretsParams, SetCalendarSecretsReturn } from "tattoo_types/src/functions/SetCalendarSecrets"
import { CreateFlashRequestParams, CreateFlashRequestReturn } from "tattoo_types/src/functions/CreateFlashRequest"
import { DeletePieceRequestParams, DeletePieceRequestReturn } from "tattoo_types/src/functions/DeletePieceRequest"
// import { MoveToArchiveParams, MoveToArchiveReturn } from "tattoo_types/src/functions/MoveToArchive"
import { SetArchiveItemProps, SetArchiveItemReturn } from "tattoo_types/src/functions/SetArchiveItem"
import { DeleteFromArchiveProps, DeleteFromArchiveReturn } from "tattoo_types/src/functions/DeleteFromArchive"
import { RefreshCalendarTokensProps, RefreshCalendarTokensReturn } from "tattoo_types/src/functions/admin/RefreshCalendarTokens"
import { AddUploadProps, AddUploadReturn } from "tattoo_types/src/functions/upload/Add"
import { DeleteUploadProps, DeleteUploadReturn } from "tattoo_types/src/functions/upload/Delete"
import { ListUploadProps, ListUploadReturn } from "tattoo_types/src/functions/upload/List"
import { CreateCustomRequestParams, CreateCustomRequestReturn } from "tattoo_types/src/functions/request/CreateCustomRequest"
import { SendAnEmailParams, SendAnEmailReturn } from "tattoo_types/src/functions/admin/SendAnEmail"
import { HasInviteProps, HasInviteReturn } from "tattoo_types/src/functions/auth/HasInvite"

let functions: Functions

const getFunctions = () => {
    const app = getApp()

    if (!functions)
        functions = getFirebaseFunctions(app)

    return functions
}

/*
class FriendlyFirebaseError extends Error {
    constructor(error: FirebaseError) {
        super([
            '',
            `name - ${error.name}`,
            `cause - ${error.cause}`,
            error.stack ? `stack - ${error.stack.split("\n").join("\n\t\t")}` : undefined,
        ].filter(notEmpty).join("\n\t"))

        this.name = "FriendlyFirebaseError"
    }
}
*/

const errorHandledHttpsCallable = <P, R>(name: string) => {
    const fn = httpsCallable<P, R>(getFunctions(), name)

    return (params: P | null | undefined): Promise<HttpsCallableResult<R>> => {
        return fn(params)
            .catch(error => {
                // if (error instanceof HttpsError)

                console.warn(`Error in firebase function ${name}`)

                /*
                if (error instanceof FirebaseError) {
                    console.log("firebase error")

                    throw new FriendlyFirebaseError(error)
                } else {
                    throw error
                }*/

                throw error
            })
            .catch(error => {
                console.error(error)

                throw null as unknown as Error
            })
    }
}


export const firebaseSetArtistProfile = errorHandledHttpsCallable<
    SetArtistProfileParams, SetArtistProfileReturn
>("setArtistProfile")


export const firebaseSetPieceImages = errorHandledHttpsCallable<
    SetPieceImagesParams, SetPieceImagesReturn
>("setPieceImages")


export const firebaseGenerateFlashSlug = errorHandledHttpsCallable<
    GenerateFlashSlugParams, GenerateFlashSlugReturn
>("generateFlashSlug")


export const firebaseSetFlash = errorHandledHttpsCallable<
    SetFlashParams, SetFlashReturn
>("setFlash")
export const firebaseDeleteFlash = errorHandledHttpsCallable<
    DeleteFlashParams, DeleteFlashReturn
>("deleteFlash")

// export const firebaseSetAvailability = httpsCallable < { availability: BasicAvailability }>(getFunctions(), "setAvailability")
// export const firebaseGetAvailability = httpsCallable < { full?: boolean, uid?: string }, Calendar>(getFunctions(), "getAvailability")

/*
export const firebaseGetAvailability = errorHandledHttpsCallable<
    GetAvailabilityParams, GetAvailabilityReturn
>("getAvailability")
*/
export const firebaseGetAvailability_v3 = errorHandledHttpsCallable<
    GetAvailabilityParams, GetAvailabilityReturn
>("getAvailability_v3")


export const firebaseSetCalendarSecrets = errorHandledHttpsCallable<
    SetCalendarSecretsParams, SetCalendarSecretsReturn
>("setCalendarSecrets")


export const firebaseCreateFlashRequest = errorHandledHttpsCallable<
    CreateFlashRequestParams, CreateFlashRequestReturn
>("createFlashRequest")
export const firebaseCreateCustomRequest = errorHandledHttpsCallable<
    CreateCustomRequestParams, CreateCustomRequestReturn
>("createCustomRequest")
export const firebaseDeletePieceRequest = errorHandledHttpsCallable<
    DeletePieceRequestParams, DeletePieceRequestReturn
>("deletePieceRequest")

/*
export const firebaseMoveToArchive = errorHandledHttpsCallable<
    MoveToArchiveParams, MoveToArchiveReturn
>("moveToArchive")
*/
export const firebaseSetArchiveItem = errorHandledHttpsCallable<
    SetArchiveItemProps, SetArchiveItemReturn
>("setArchiveItem")
export const firebaseDeleteFromArchive = errorHandledHttpsCallable<
    DeleteFromArchiveProps, DeleteFromArchiveReturn
>("deleteFromArchive")


export const firebaseAuthHasInvite = errorHandledHttpsCallable<
    HasInviteProps, HasInviteReturn
>("auth-hasInvite")

export const firebaseUploadFn = {
    add: errorHandledHttpsCallable<
        AddUploadProps, AddUploadReturn
    >("upload-add"),
    list: errorHandledHttpsCallable<
        ListUploadProps, ListUploadReturn
    >("upload-list"),
    delete: errorHandledHttpsCallable<
        DeleteUploadProps, DeleteUploadReturn
    >("upload-delete")
}

export const firebaseAdminFn = {
    publicizeImages: errorHandledHttpsCallable<
        void, void
    >("adminPublicizeImages"),
    refreshCalendarTokens: errorHandledHttpsCallable<
        RefreshCalendarTokensProps, RefreshCalendarTokensReturn
    >("adminRefreshCalendarTokens"),
    manuallyDeleteUploads: errorHandledHttpsCallable<
        void, string[]
    >("adminManuallyDeleteUploads"),
    sendAnEmail: errorHandledHttpsCallable<
        SendAnEmailParams, SendAnEmailReturn
    >("adminSendAnEmail")
}

export default getFunctions