import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { firebaseGetAvailability } from "../../firebase/functions";
import actionTypes from "../actionTypes";
import { firebaseGetAvailability_v3 } from "../../firebase/functions";
import { Appointment } from "tattoo_types/src/Calendar"

// Annoying ass helper due to Redux's requirements
const getAvailabilityWithAnnoyingArrayArgs = createAsyncThunk<{
    availability: Appointment[],
    uid: string,
    full?: boolean
}, [string?, boolean?]>(
    actionTypes.GET_AVAILABILITY,
    ([uidOrNull, full]) => {
        // TODO I don't love this logic
        const uid = uidOrNull ?? "self"

        if (process.env.NODE_ENV === "development") {
            console.log(`Loading ${full ? "full" : "partial"} availability uid = ${uid}`)
        }

        return firebaseGetAvailability_v3({ uid, full })
            .then((result) => {
                const { availability } = result.data

                return {
                    uid,
                    full,
                    availability
                }
            })
    }
)


export const getAvailability = (artistUid?: string, full?: boolean) =>
    getAvailabilityWithAnnoyingArrayArgs([artistUid, full])

const initialState: {
    'getAvailability': Record<string, { partial?: Appointment[], full?: Appointment[] }>
} = {
    'getAvailability': {}
}

const firebaseFunctionsSlice = createSlice({
    name: "firebase/functions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAvailabilityWithAnnoyingArrayArgs.fulfilled, (state, action) => {
            const { full, availability, uid } = action.payload

            if (!state['getAvailability'][uid])
                state['getAvailability'][uid] = {}

            state['getAvailability'][uid][full ? "full" : "partial"] = availability
        })
    }
})

export default firebaseFunctionsSlice