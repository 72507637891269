
const joinFont = (...fonts: string[]) => fonts.join(", ")

const studioConfig = {
    color: {
        primary: "#8a7547",
        secondary: "#292623",
        warning: "#ff5b78",
        danger: "#bf1b3c",

        background: "#141311",
    },
    font: {
        family: {
            heading: joinFont("Chomsky", "GFS Didot", "serif"),
            body: joinFont("GFS Didot", "serif")
        }
    },
    origin: "https://sundogplace.com",
    name: "Sundog Tattoos"
}

export default studioConfig