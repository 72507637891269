import { Analytics, getAnalytics as firebaseGetAnalytics } from "firebase/analytics";
import getApp from ".";

let analytics: Analytics

const getAnalytics = () => {
    const app = getApp()

    if (!analytics)
        analytics = firebaseGetAnalytics(app);

    return analytics
}

export default getAnalytics