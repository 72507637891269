import { FirebaseApp, initializeApp } from "firebase/app"

let app: FirebaseApp

const getApp = () => {
    const config = {
        apiKey: "AIzaSyAmvIGJrE5N4FIbpjq95FjByQ7J7IbXtrI",
        authDomain: "sundogtt.firebaseapp.com",
        projectId: "sundogtt",
        storageBucket: "sundogtt-upload",
        messagingSenderId: "895551883508",
        appId: "1:895551883508:web:1ef8b1b1ee60e8105136a1",
        measurementId: "G-4Z23MLXSM5"
    }

    if (!app)
        app = initializeApp(config)

    return app
}


export default getApp