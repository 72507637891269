import { experimental_extendTheme as materialExtendTheme, } from "@mui/material";
import { extendTheme as joyExtendTheme } from "@mui/joy";
import studioConfig from "./studioConfig";

// https://mui.com/joy-ui/integrations/material-ui/

export const materialTheme = materialExtendTheme({
    colorSchemes: {
        dark: {
            palette: {
                secondary: {
                    main: studioConfig.color.secondary,
                },
                primary: {
                    // This sets the color of loaders, footer, etc etc
                    main: studioConfig.color.primary,
                },
                warning: {
                    main: studioConfig.color.warning
                },
                error: {
                    main: studioConfig.color.danger
                }
            }
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: studioConfig.color.secondary,
                    
                    backgroundImage: "unset"
                }
            }
        },
        /*
        MuiTypography: {
            styleOverrides: {
                fontWeight: "bold"
            }
        }
        */
    },
    typography: {
        fontFamily: studioConfig.font.family.body,
        //fontWeightRegular: "bold",
        //fontWeightMedium: "bold",
        //fontWeightBold: "bold",
        fontSize: 16,
        button: {
            textTransform: "none"
        },
        h1: {
            fontFamily: studioConfig.font.family.heading
        },
        h2: {
            fontFamily: studioConfig.font.family.heading
        },
        h3: {
            fontFamily: studioConfig.font.family.heading
        }
    }
})

export const joyTheme = joyExtendTheme({
    typography: {
        "body-md": {
            fontFamily: studioConfig.font.family.body,
            fontSize: 18,
        },
        "body-sm": {
            fontFamily: studioConfig.font.family.body,
        },
        "title-sm": {
            fontFamily: studioConfig.font.family.heading,
            fontSize: "1.5rem"
        },
        "title-md": {
            fontFamily: studioConfig.font.family.heading,
            fontSize: "1.75rem"
        },
        "title-lg": {
            fontFamily: studioConfig.font.family.heading,
            fontSize: "2rem"
        },
    },
    components: {
        JoyCard: {
            styleOverrides: {
                root: {
                    borderColor: "#57503f"
                }
            }
        }
    },
    colorSchemes: {
        dark: {
            palette: {
                warning: {
                    400: studioConfig.color.warning
                },
                danger: {
                    400: studioConfig.color.warning
                }
            }
        }
    }
})