import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import actionTypes from "../actionTypes";
import Size from "tattoo_types/src/Size";

const initialState: { 
    preferredUnit: Size['unit'] | undefined
} = {
    preferredUnit: undefined
}

const clientSlice = createSlice({
    name: "markdown",
    initialState,
    reducers: {
        setPreferredUnit(state, action: PayloadAction<Size['unit']>) {
            state.preferredUnit = action.payload
        },
    },
})

export const { setPreferredUnit } = clientSlice.actions

export default clientSlice