import { useRouteError, useNavigate } from "react-router-dom"
import DefaultLayout from "../layouts/DefaultLayout"
import Center from "../components/Center"
import { Typography, Card } from "@mui/joy"
import { Button, Grid } from "@mui/material"

const ErrorComponent: React.FC = () => {
    const error = useRouteError()

    console.error(error);

    const errorIsObject = error && typeof error === "object"
    const status: string | undefined = errorIsObject && Object.hasOwn(error, "status") ?
        (error as any).status : undefined

    const statusText: string | undefined = errorIsObject && Object.hasOwn(error, "statusText") ?
        (error as any).statusText : undefined

    const statusInfo =
        errorIsObject && status && statusText ?
            <>
                <Typography display="inline">{status} - {statusText}</Typography>
            </> :
            <Typography>We don't have any more information</Typography>

    const navigate = useNavigate()

    // TODO if history contains origin, go back, else navigate('/')
    // is this even possible? who knows!

    const back = () => navigate("/")

    return (
        <DefaultLayout>
            <Center height="100%">
                <Card>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography level="title-lg">
                                An error occurred!
                            </Typography>

                            {statusInfo}
                        </Grid>

                        <Grid item xs={8}>
                            <img src="/asset/sisu.png" width="100%" alt="Sisu the cat :)" />
                        </Grid>
                    </Grid>

                    <Button
                        color="primary"
                        variant="contained"

                        onClick={back}

                    //component={RouterLink}
                    //to="/"
                    >
                        Back to safety!
                    </Button>
                </Card>
            </Center>
        </DefaultLayout>
    )
}

export default ErrorComponent